<template>
  <q-layout view="hHr Lpr lFr" class="dashboard">
    <the-titlebar />

    <the-left-drawer />
    <!--<q-drawer v-model="rightDrawerOpen" side="right" overlay elevated></q-drawer>-->

    <q-page-container>
      <div class="page-content">
        <router-view v-slot="{ Component }">
          <transition appear name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </q-page-container>
  </q-layout>
</template>

<script>
import TheTitlebar from "@/components/TheTitlebar";
import TheLeftDrawer from "@/components/TheLeftDrawer";

export default {
  name: "Dashboard",
  components: {
    TheTitlebar,
    TheLeftDrawer
  }
};
</script>

<style lang="scss">
.dashboard {
  .page-content {
    padding: $space-xl $space-lg;
  }
}
</style>
