<template>
  <q-header elevated class="bg-dark text-white">
    <q-toolbar class="q-px-md q-gutter-x-md">
      <q-btn
        dense
        flat
        round
        :ripple="false"
        icon="menu"
        @click="$store.commit('toggleLeftDrawer')"
      />

      <q-toolbar-title class="flex items-center">
        <router-link to="/" class="flex">
          <img
            src="@/assets/images/logos/jumpfeed-full-light.svg"
            style="width: 125px; align-self: flex-start;"
          />
        </router-link>
      </q-toolbar-title>

      <q-btn-dropdown stretch flat no-caps :label="userDisplayName">
        <q-list>
          <q-item clickable v-close-popup tabindex="0" to="/logout">
            <q-item-section>
              <q-item-label>Sign Out</q-item-label>
            </q-item-section>
            <q-item-section avatar>
              <q-avatar icon="logout" text-color="primary" />
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>

      <!--<q-btn dense flat round icon="menu" @click="toggleRightDrawer" />-->
    </q-toolbar>
  </q-header>
</template>

<script>
export default {
  name: "TheTitlebar",
  computed: {
    userDisplayName() {
      return this.$store.getters["user/displayName"];
    }
  }
};
</script>
